import { graphql } from 'gatsby';
import { BackGround } from '../../components/background';
import { BreadCrumb } from '../../components/breadcrumb';
import { Layout } from '../../components/layout';
import { Seo } from '../../components/seo';
import { dayjs } from "../../lib/dayjs";
import * as Styles from '../../styles/pages/_vote.module.scss';
import Reload from '/static/reload.svg';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import {Link, useIntl} from "gatsby-plugin-react-intl"
import {reloadPage} from "../../../.cache/fast-refresh-overlay/utils";
import axios from "axios";
import { contentPathHelper } from '../../utils/contentPathHelper';
import * as VoteStyles from "../../styles/pages/_vote.module.scss";

const voteArtist = async ({ matchData, artist, index, setDispSuccess, setDispFailure }) => {
  const apiKey = process.env.LIMITS_API_KEY;
  const apiUrl = process.env.LIMITS_API_URL;

  let uuid = undefined
  const data = localStorage.getItem(`limits-user-${matchData.matchId}`)
  if (data) {
    const userData = JSON.parse(data)
    uuid = userData?.uuid
  }
  const params = {
    match_id: matchData.matchId,
    red_point: artist === 'red' ? 1 : 0,
    blue_point: artist === 'blue' ? 1 : 0,
    uuid
  }
  await axios
    .post(apiUrl + '/api/spectator_votes', params, {
      headers: {
        'Content-Type': 'application/json',
        'LIMITS-BATTLESYSTEM-API-KEY': apiKey
      }
    })
    .then((response) => {
      if (response.data.result === 0) {
        const data = JSON.stringify({
          uuid: response.data.uuid,
          voted: artist
        })
        localStorage.setItem(`limits-user-${matchData.matchId}`, data)
        setDispSuccess(true)
        setTimeout(() => {
          setDispSuccess(false)
        }, 3000)
        const elementBlue = document.getElementById('artist-box-blue')
        const elementRed = document.getElementById('artist-box-red')
        if (artist === 'blue') {
          elementBlue.style.backgroundColor = 'rgba(0, 120, 206, 0.5)'
          elementRed.style.backgroundColor = 'rgba(202, 0, 108, 0.0)'
        } else if (artist === 'red') {
          elementBlue.style.backgroundColor = 'rgba(0, 120, 206, 0.0)'
          elementRed.style.backgroundColor = 'rgba(202, 0, 108, 0.5)'
        }
      } else {
        setDispFailure(true)
        setTimeout(() => {
          setDispFailure(false)
        }, 3000)
      }
    })
    .catch((error) => {
      console.error(error)
      setDispFailure(true)
      setTimeout(() => {
        setDispFailure(false)
      }, 3000)
    });
}

const VotePage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const eventData = data.microcmsMatch.event
  const matchData = data.microcmsMatch

  const [dispSuccess, setDispSuccess] = React.useState(false)
  const [dispFailure, setDispFailure] = React.useState(false)

  return (
    <Layout location={location}>
      <Seo title={t('vote.seo.title')} description={t('vote.seo.description')} />
      <div className={Styles.vote_bar}>
        <p id={'vote-success'} className={(dispSuccess ? VoteStyles.disp : '')}>{t('vote.voted')}</p>
      </div>
      <div className={Styles.vote_bar_ng}>
        <p id={'vote-failure'} className={(dispFailure ? VoteStyles.disp : '')}>{t('vote.voteFailed')}</p>
      </div>
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('vote.voting')}
      />
      <BackGround>
        <section className={Styles.vote}>
          <div className={Styles.vote__wrap}>
            <div className={Styles.vote__inner}>
              <div className={Styles.vote__img}>
                <img src={eventData.image.url} alt='' />
              </div>
              <div className={Styles.vote__sp_style}>
                <div className={Styles.vote__match_data}>
                  <h1>{eventData.title}</h1>
                  <div className={Styles.vote__box}>
                    <p className={Styles.vote__date}>
                      {dayjs(matchData.date).format('M月D日(ddd)')}{' '}
                    </p>
                    <p className={Styles.vote__game}>{matchData.title}</p>
                    <div className={Styles.vote__category}>
                      <p id="theme-concrete" className={Styles.vote__theme}>{matchData.concrete}</p>
                      <span>+</span>
                      <p id="theme-abstract" className={Styles.vote__theme}>{matchData.abstract}</p>
                    </div>
                  </div>
                </div>
                <div className={Styles.vote__match}>
                  <div>
                    <div className={Styles.vote__match_set}>
                      <div className={Styles.vote__artist_box_blue} id="artist-box-blue">
                        <div className={Styles.vote__artist}>
                          <img id="blue-artist-icon" src="" alt="" />
                        </div>
                        <p className={Styles.vote__artist_name}>
                          <Link id="blue-artist-name"></Link>
                        </p>
                      </div>
                      <p className={Styles.vote__blue_btn}>
                        <button onClick={() => voteArtist({ matchData, artist: 'blue', index: 0, setDispSuccess, setDispFailure })}>
                          {t('vote.vote')}
                        </button>
                      </p>
                    </div>
                    {/*{matchData.blue && matchData.blue.length > 0 && matchData.blue.map((blue, index) => (*/}
                    {/*  <div key={index} className={Styles.vote__match_set}>*/}
                    {/*    <div className={Styles.vote__artist}>*/}
                    {/*      <img src={blue.image_url || blue.image.url} alt={`${blue.name}${t('match.icon')}`} />*/}
                    {/*    </div>*/}
                    {/*    <p className={Styles.vote__artist_name}>*/}
                    {/*      <Link to={'/artist/' + contentPathHelper(blue.id) + '/'}>{blue.name}</Link>*/}
                    {/*    </p>*/}
                    {/*    <p className={Styles.vote__blue_btn}>*/}
                    {/*      <button onClick={() => voteArtist({ matchData, artist: 'blue', index, setDispSuccess, setDispFailure })}>*/}
                    {/*        {t('vote.vote')}*/}
                    {/*      </button>*/}
                    {/*    </p>*/}
                    {/*  </div>*/}
                    {/*))}*/}
                  </div>
                  <p className={Styles.vote__vs}>VS</p>
                  <div>
                    <div className={Styles.vote__match_set}>
                      <div className={Styles.vote__artist_box_red} id="artist-box-red">
                        <div className={Styles.vote__artist}>
                          <img id="red-artist-icon" src="" alt="" />
                        </div>
                        <p className={Styles.vote__artist_name}>
                          <Link id="red-artist-name"></Link>
                        </p>
                      </div>
                      <p className={Styles.vote__red_btn}>
                        <button onClick={() => voteArtist({ matchData, artist: 'red', index: 0, setDispSuccess, setDispFailure })}>
                          {t('vote.vote')}
                        </button>
                      </p>
                    </div>
                    {/*{matchData.red && matchData.red.length > 0 && matchData.red.map((red, index) => (*/}
                    {/*  <div key={index} className={Styles.vote__match_set}>*/}
                    {/*    <div className={Styles.vote__artist}>*/}
                    {/*      <img src={red.image_url || red.image.url} alt={`${red.name}${t('match.icon')}`} />*/}
                    {/*    </div>*/}
                    {/*    <p className={Styles.vote__artist_name}>*/}
                    {/*      <Link to={'/artist/' + contentPathHelper(red.id) + '/'}>{red.name}</Link>*/}
                    {/*    </p>*/}
                    {/*    <p className={Styles.vote__red_btn}>*/}
                    {/*      <button onClick={() => voteArtist({ matchData, artist: 'red', index, setDispSuccess, setDispFailure })}>*/}
                    {/*        {t('vote.vote')}*/}
                    {/*      </button>*/}
                    {/*    </p>*/}
                    {/*  </div>*/}
                    {/*))}*/}
                  </div>
                </div>
                <div className={Styles.vote__annotation}>
                  ・1人につき1票をクリエイターへ投票できます<br/>
                  ・投票受付中は投票先を変更できます<br/>
                  ・複数回投票を行った場合、最後に選んだクリエイターへの投票が有効となります<br/>
                </div>
              </div>
            </div>
            <p className={Styles.vote__reload_btn}>
              <button onClick={() => window.location.reload()}>
                <img src={Reload} className='reload' alt='reload' />
                {t('vote.pageReload')}
              </button>
            </p>
            <p className={Styles.vote__back_btn}>
              <Link to='/'>{t('vote.returnTop')}</Link>
            </p>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
}

export const query = graphql`
  query ($matchId: String) {
    microcmsMatch(matchId: { eq: $matchId }) {
      matchId
      event {
        image {
          url
        }
        title
      }
      title
      date
      red {
        name
        image_url
        image {
          url
        }
        id
      }
      red_point
      blue {
        name
        image_url
        image {
          url
        }
        id
      }
      blue_point
      concrete
      abstract
      video_id
      publishedAt
      winner
    }
  }
`;

export default VotePage;
